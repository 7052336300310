<template>
  <div>
    <v-chip v-if="waitingForResponsableTechnique(intervention)" color="primary" class="white--text">A valider</v-chip>
    <v-chip v-else-if="intervention.prestataire === null" color="red" class="white--text">Non assigné</v-chip>
    <v-chip v-else-if="!intervention.validation" color="orange" class="white--text">En attente de retour</v-chip>
    <v-chip v-else-if="intervention.validation && intervention.waiting" class="orange darken-3 white--text">En cours</v-chip>
    <v-chip v-else-if="intervention.validation && intervention.date_realisation !== null" class="blue darken-3 white--text">Réalisée</v-chip>
    <v-chip v-else-if="intervention.validation" color="green" class="white--text">Assigné</v-chip>
  </div>
</template>
<script>
import InterventionMixin from "@/mixins/interventions/InterventionMixin";

export default {
  name: 'InterventionStatutChip',
  mixins: [InterventionMixin],
  props: {
    intervention: {}
  }
}
</script>