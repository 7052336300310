<template>
  <v-row no-gutters justify="space-around">
    <v-col cols="12" class="my-2">
      <h4>Notation et coût</h4>
    </v-col>
    <v-col v-if="hasFinanceEntry && selected_intervention.cout_achat === null && isResponsableOrAdmin" sm="4" cols="12">
      <v-text-field class="px-4" label="Montant HT" v-model="cout"></v-text-field>
    </v-col>
    <v-col v-if="(!hasFinanceEntry || selected_intervention.cout_achat !== null) && isResponsableOrAdmin" sm="4" cols="12">
      <detail-card icon="mdi-currency-eur" name="Cout"
                   :information="selected_intervention.cout_achat != null ? selected_intervention.cout_achat + ' EUR' : 'Non défini'"/>
    </v-col>
    <v-col v-if="selected_intervention.note === null && canNoteIntervention" sm="4" cols="12">
      <v-row class="px-2">
        <v-col cols="3" sm="2" xl="2">
          <v-icon large>mdi-star</v-icon>
        </v-col>
        <v-col cols="9" sm="10" xl="10" class="px-0 py-2">
          <div>Note</div>
          <v-rating length="5" empty-icon="mdi-star-outline"
                    v-model="note"
                    full-icon="mdi-star"
                    half-icon="mdi-star-half-full"></v-rating>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="canNoteIntervention && selected_intervention.note !== null" sm="4" cols="12">
      <detail-card icon="mdi-thumbs-up-down" name="Note"
                   :information="selected_intervention.note != null ? selected_intervention.note + ' / 5' : 'Non défini'"/>
    </v-col>
    <v-col v-if="selected_intervention.entry != null" sm="4" cols="12">
      <detail-card icon="mdi-currency-eur" name="Entrée financière"
                   :clickable="selected_intervention.entry !== null"
                   @click.native="selected_intervention.entry !== null ? $router.push(`/finance?id=${selected_intervention.entry}`) : null"
                   :information="'#' + selected_intervention.entry"/>
    </v-col>
    <v-col cols="12" class="mb-2 ml-2">
      <v-btn v-if="canNoteIntervention && selected_intervention !== null
                           && selected_intervention.note === null" @click="setRating" class="primary">
        <span class="white--text"><v-icon>mdi-sparkles</v-icon> Affecter note</span>
      </v-btn>
      <v-btn v-if="selected_intervention !== null
                           && (selected_intervention.cout_achat === null) && isResponsableOrAdmin && hasFinanceEntry" @click="setCost"
             class="primary">
        <span class="white--text"><v-icon>mdi-sparkles</v-icon> Affecter coût uniquement</span>
      </v-btn>
      <v-dialog v-if="isResponsableOrAdmin || hasPermission('add_entry')" v-model="financeDialog" width="500">
        <template v-slot:activator="{on, attrs}">
          <v-btn v-if="selected_intervention.entry === null && isResponsableOrAdmin"
                 v-on="on" v-bind="attrs" class="primary">
            <span class="white--text"><v-icon>mdi-currency-eur</v-icon> Associer à une entrée financière</span>
          </v-btn>
        </template>
        <entry-form operation="create"
                    :entry-intervention="selected_intervention.id"
                    :entry-asset="ticket.asset !== null ? ticket.asset.id : null"
                    :entry-contract="ticket.asset !== null ? ticket.asset.contract !== null ? ticket.asset.contract : null : null"
                    :entry-prestataire="selected_intervention.prestataire !== null ? selected_intervention.prestataire.id : null"
                    :key="selected_intervention.id" @close-drawer="closeDrawer"></entry-form>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import DetailCard from "@/components/base/DetailCard"
import EntryForm from "@/components/finance/EntryForm"
import {AuthMixin} from "@/mixins/util/AuthMixin";

export default {
  name: 'Notation',
  components: {DetailCard, EntryForm},
  mixins: [AuthMixin],
  data() {
    let intervention = this.$store.getters["interventions/getSelectedIntervention"];
    return {
      tab: null,
      cout: intervention ? intervention.cout_achat : null,
      note: intervention ? intervention.note : null,
      financeDialog: false,
    }
  },
  computed: {
    isNotSelfAssigned() {
      return parseInt(this.logged_user?.id) !== parseInt(this.selected_intervention.prestataire?.id);
    },

    hasRatingPermission() {
      return [
        "Responsable technique",
        "Administrateur",
      ].indexOf(this.logged_user?.group) !== -1 || this.hasPermission("can_note");
    },
    isTicketCreator() {
      return this.ticket?.created_by.id === this.logged_user?.id;
      },
    canNoteIntervention() {
      return  (this.isNotSelfAssigned || this.isResponsableOrAdmin) && (this.hasRatingPermission || this.isTicketCreator);
    },
    hasFinanceEntry() {
      return this.selected_intervention.entry !== null;
    },
    selected_intervention() {
      let intervention = this.$store.getters["interventions/getSelectedIntervention"];
      return intervention === null ? this.$store.getters["tickets/getSelectedTicket"].intervention : intervention;
    },
    ticket() {
      let intervention = this.$store.getters["interventions/getSelectedIntervention"];
      return intervention?.ticket == null ? this.$store.getters["tickets/getSelectedTicket"] : intervention.ticket;
    },
  },
  methods: {
    async setCostAndRating() {
      try {
        await this.$store.dispatch("interventions/patchIntervention", {
          id: this.selected_intervention.id,
          cout_achat: this.cout,
          note: this.note,
        });
        await this.$store.dispatch("tickets/getTicketById", this.selected_intervention.ticket.id)
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Prix et note affecté."
        });
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue."
        });
      }
    },
    async setCost() {
      try {
        await this.$store.dispatch("interventions/patchIntervention", {
          id: this.selected_intervention.id,
          cout_achat: this.cout,
        });
        await this.$store.dispatch("tickets/getTicketById", this.selected_intervention.ticket.id)
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Prix affecté."
        });
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue."
        });
      }
    },
    async setRating() {
      try {
        await this.$store.dispatch("interventions/patchIntervention", {
          id: this.selected_intervention.id,
          note: this.note,
        });
        await this.$store.dispatch("tickets/getTicketById", this.selected_intervention.ticket.id)
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Intervention noté."
        });
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue."
        });
      }
    },
    async closeDrawer() {
      this.financeDialog = false;
      if(this.selected_intervention !== null) await this.$store.dispatch("interventions/getInterventionById", this.selected_intervention.id);
    },
  },
}
</script>
