const STATUS_LABELS = {
    "running": "En cours",
    "no_answer_yet": "En attente de réponse",
    "open": "Ouvert",
    "solved": "Résolu",
    "closed": "Cloturé",
    "ongoing": "En cours",
    "waiting": "En attente de réponse",
    "processed": "Traité",
    "ready": "Assigné",
    "finished": "Réalisé",
    "unassigned": "Non assigné",
    "paused": "En suspens"
}

const STATUS_COLORS = {
    "running": "yellow--text text--darken-1",
    "no_answer_yet": "orange--text",
    "open": "green--text",
    "solved": "blue--text text--darken-3",
    "closed": "black--text",
    "ongoing": "yellow--text text--darken-1",
    "waiting": "green--text",
    "processed": "primary--text",
    "ready": "green--text text--darken-1",
    "finished": "blue--text text--darken-3",
    "unassigned": "red--text text--darken-1",
    "paused": "brown--text text--darken-1"
}

const PRIORITY_LABELS = {
  "low": "Basse",
  "medium": "Moyenne",
  "high": "Élevée"
};

const PRIORITY_COLORS = {
    "low": "green",
    "medium": "yellow",
    "high": "red"
};

export {STATUS_LABELS, STATUS_COLORS, PRIORITY_LABELS, PRIORITY_COLORS};