<template>
  <v-card elevation="0" flat :loading="loading">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title>Modifier l'intervention</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form class="px-3">
        <v-row>
          <v-col cols="8">
            <v-menu v-model="menu_date_debut" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false"
                    :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field :rules="[notNullNorEmpty]" v-on="on" v-bind="attrs" label="Date du début"
                              :value="getFormattedDate(date_debut)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="date_debut" @change="date_fin = date_debut"></v-date-picker>
                <v-card-actions>
                  <v-btn @click="menu_date_debut = false" text color="primary">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu v-model="menu_heure_debut" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false"
                    :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure du début"
                              :value="heure_debut"></v-text-field>
              </template>
              <v-card>
                <v-time-picker format="24hr" v-model="heure_debut"></v-time-picker>
                <v-card-actions>
                  <v-btn @click="menu_heure_debut = false" text color="primary">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-menu v-model="menu_date_fin" max-width="100%" :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Date du fin"
                              :value="getFormattedDate(date_fin)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="date_fin"></v-date-picker>
                <v-card-actions>
                  <v-btn color="primary" text @click="menu_date_fin = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu v-model="menu_heure_fin" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure de fin"
                              :value="heure_fin"></v-text-field>
              </template>
              <v-card>
                <v-time-picker format="24hr" v-model="heure_fin"></v-time-picker>
                <v-card-actions>
                  <v-btn color="primary" text @click="menu_heure_fin = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-menu v-model="menu_date_realise" max-width="290" class="ma-0 pa-0" :close-on-content-click="false"
                    :close-on-click="true">>
              <template v-slot:activator="{on, attrs}">
                <v-text-field persistent-hint
                              hint="Assigner une date de réalisation à une intervention non réalisée changera le statut du ticket."
                              v-on="on" v-bind="attrs" label="Date de réalisation"
                              :value="getFormattedDate(date_realise)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="date_realise"></v-date-picker>
                <v-card-actions>
                  <v-btn color="primary" text @click="menu_date_realise = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu v-model="menu_heure_realise" max-width="290" class="ma-0 pa-0" :close-on-content-click="false"
                    :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure de réalisation"
                              :value="heure_realise"></v-text-field>
              </template>
              <v-card>
                <v-time-picker format="24hr" v-model="heure_realise"></v-time-picker>
                <v-card-actions>
                  <v-btn color="primary" text @click="menu_heure_realise = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-divider class="py-4"></v-divider>
        <v-row no-gutters>
          <v-col cols="12">
            <h4 class="grey--text">Changer l'intervenant</h4>
          </v-col>
          <v-col cols="11">
            <v-select label="Filtrer par métier"
                      :items="metiers"
                      v-model="metier"
                      item-text="name"
                      item-value="id"></v-select>
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="metier = null" class="mt-4 ml-2" color="red" large v-on="on" v-bind="attrs">
                  mdi-close
                </v-icon>
              </template>
              <span>Retirer le métier</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <v-select item-text="fullname" item-value="id" label="Intervenant" v-model="intervenant"
                      :items="filtered_intervenants"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox label="Validation prestataire" v-model="validation"></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="editHour">
        Modifier
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";
import InterventionMixin from "@/mixins/interventions/InterventionMixin";
import dayjs from "dayjs";
/**
 * @typedef Intervention
 * @type {object}
 * @property {number} id - an ID.
 * @property {string} date_creation
 * @property {boolean} last_reminder
 * @property {Object|number} ticket
 * @property {Object|number} prestataire
 * @property {Object|number} categorie
 * @property {string} date_intervention
 * @property {string} date_fin_intervention
 * @property {string} date_realisation
 * @property {string} date_reponse
 * @property {boolean} validation
 * @property {boolean} waiting
 * @property {number} cout_achat
 * @property {number} note
 */

export default {
  name: 'InterventionEditionForm',
  mixins: [DateUtilMixin, AuthMixin, ValidationRulesMixin, InterventionMixin],
  async mounted() {
    if (this.$store.getters["profile/profiles"].length === 0) {
      await this.$store.dispatch("profile/fetchProfiles");
    }
    if (this.$store.getters["categories/allCategories"].length === 0) {
      await this.$store.dispatch("categories/fetchCategories");
    }
  },
  data() {
    let intervention = this.$store.getters["interventions/getSelectedIntervention"];
    return {
      "date_debut": intervention?.date_intervention !== null ? dayjs(intervention?.date_intervention).format("YYYY-MM-DD") : null,
      "heure_debut": intervention?.date_intervention !== null ? dayjs(intervention?.date_intervention).format("HH:mm") : null,
      "date_fin": intervention?.date_fin_intervention !== null ? dayjs(intervention?.date_fin_intervention).format("YYYY-MM-DD") : null,
      "heure_fin": intervention?.date_fin_intervention !== null ? dayjs(intervention?.date_fin_intervention).format("HH:mm") : null,
      "date_realise": intervention?.date_realisation !== null ? dayjs(intervention?.date_realisation).format("YYYY-MM-DD") : null,
      "heure_realise": intervention?.date_realisation !== null ? dayjs(intervention?.date_realisation).format("HH:mm") : null,
      "validation": intervention?.validation !== null ? intervention?.validation : false,
      "loading": false,
      "menu_date_debut": false,
      "menu_heure_debut": false,
      "menu_date_fin": false,
      "menu_heure_fin": false,
      "menu_date_realise": false,
      "menu_heure_realise": false,
      "intervenant": intervention?.prestataire !== null ? intervention.prestataire : null,
      "metier": null,
    };
  },
  computed: {
    /**
     *
     * @returns {Intervention}
     */
    selected_intervention() {
      return this.$store.getters["interventions/getSelectedIntervention"];
    },
    filtered_intervenants() {
      return this.intervenants.filter(intervenant => this.metier === null ?  true : intervenant.metiers?.includes(this.metier))
    },
    intervenants() {
      return this.$store.getters["profile/intervenants"];
    },
    metiers() {
      return [{id: null, name: "Tout les métiers"}, ...this.$store.getters["categories/metiers"]];
    }
  },
  methods: {
    async editHour() {
      this.cleanHours();
      this.loading = true;
      let intervention = {
        "id": this.selected_intervention.id,
        "date_intervention": this.getCleanDate(this.date_debut, this.heure_debut),
        "date_fin_intervention": this.getCleanDate(this.date_fin, this.heure_fin),
        "date_realisation": this.getCleanDate(this.date_realise, this.heure_realise),
        "validation": this.validation,
        "prestataire": this.intervenant.id,
      }
      await this.$store.dispatch("interventions/patchIntervention", intervention);
      await this.$store.dispatch("messages/announceSuccess", "Intervention modifié.")
      this.loading = false;
      this.$store.dispatch('drawer/closeDialog');
      this.$root.$emit("reloadInterventionPage");
    },
    cleanHours() {
      if (this.date_realise !== null && this.heure_realise === null) {
        this.heure_realise = "12:00";
      }
      if (this.date_debut !== null && this.heure_debut === null) {
        this.heure_debut = "12:00";
      }
      if (this.date_fin !== null && this.heure_fin === null) {
        this.heure_fin = "12:00";
      }
    }
  }
}
</script>