import {PRIORITY_LABELS, STATUS_COLORS, STATUS_LABELS} from "@/constants/tickets";
import dayjs from "dayjs";

const TicketMixin = {
    created() {
    },
    methods: {
        setTicket(ticket) {
            this.$store.commit("tickets/setSelectedTicket", ticket);
            const path = `/tickets/${ticket.id}`;
            if (this.$route.path !== path) this.$router.push(path);
        },
        getStatusDisplay(status) {
            return STATUS_LABELS[status]
        },
        getStatusColor(status) {
            return STATUS_COLORS[status]
        },
        getPriorityLabels(priority) {
            return PRIORITY_LABELS[priority];
        },
        formatDate(date) {
            return dayjs(date).format("DD MMMM YYYY à HH:mm");
        },
    },
    computed: {
        priority_labels() {
          return PRIORITY_LABELS;
        },
    }
}

export default TicketMixin;