<template>
  <div>
    <v-card class="mx-0 pa-2" elevation="0" v-if="selected_intervention !== null && loaded_intervention" outlined>
      <v-row no-gutters>
        <v-col cols="12">
          <v-row justify="space-between">
            <v-col md="6" xs="4" xl="8" cols="12">
              <h3 class="blue--text text--darken-2">Intervention du ticket #{{ selected_intervention.ticket.id }} :
                {{ selected_intervention.ticket.objet }}</h3>
            </v-col>
            <v-col xs="3" cols="12">
              <v-btn text v-if="isResponsableOrAdmin || hasPermission('change_contract')" @click="editIntervention(selected_intervention)" color="primary">
                <v-icon>mdi-pencil</v-icon>
                Modifier
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols='12'>
          <InterventionStatutChip :intervention="selected_intervention"/>
        </v-col>
        <v-col cols="12">
          <p v-if="selected_intervention.waiting" class="orange--text ma-2">
            <v-icon>mdi-warning</v-icon>
            Pour plus d'informations, voir les commentaires.
          </p>
        </v-col>
        <v-col cols="12" class="mt-2">
          <h3 class="grey--text">Descriptif</h3>
          <p>
            {{ selected_intervention.ticket.description }}
          </p>
        </v-col>
        <v-col cols="6">
          <detail-card name="Début de l'intervention" icon="mdi-calendar-arrow-right"
                       :information="selected_intervention.date_intervention !== null ? formatDate(selected_intervention.date_intervention) : 'Non défini'"></detail-card>
        </v-col>
        <v-col cols="6">
          <detail-card name="Fin de l'intervention" icon="mdi-calendar-check"
                       :information="selected_intervention.date_fin_intervention !== null ? formatDate(selected_intervention.date_fin_intervention) : 'Non défini'"></detail-card>
        </v-col>
        <v-col cols="8" offset="2" v-if="selected_intervention.ticket.asset !== null">
          <detail-card name="Site" icon="mdi-map-marker" :information="site_name"></detail-card>
        </v-col>
        <v-col cols="6" md="4" xl="3">
          <router-link style="text-decoration: none" :to="'/tickets/' + selected_intervention.ticket.id">
            <detail-card name="Ticket lié" clickable icon="mdi-ticket" :information="ticket_name"></detail-card>
          </router-link>
        </v-col>
        <v-col cols="6" md="4" xl="3">
          <detail-card name="Priorité" icon="mdi-priority-high"
                       :information="getPriorityLabels(ticket.priority)"></detail-card>
        </v-col>
        <v-col cols="6" md="4" xl="3" v-if="selected_intervention.ticket.asset !== null">
          <detail-card @click.native="$router.push('/assets/' + ticket.asset.id)" clickable name="Équipement"
                       icon="mdi-wrench" :information="ticket.asset.name"></detail-card>
        </v-col>
        <v-col cols="6" md="4" xl="3">
          <detail-card name="Métier" icon="mdi-toolbox"
                       :information="selected_intervention.categorie !== null ? selected_intervention.categorie.name : 'Non défini'"></detail-card>
        </v-col>
        <v-col cols="6" md="4" xl="3">
          <detail-card name="Créé par" icon="mdi-account" :information="ticket.created_by !== null ? ticket.created_by.fullname : 'Utilisateur supprimé'"></detail-card>
        </v-col>
        <v-col cols="6" md="4" xl="3">
          <detail-card name="Intervenant" icon="mdi-account"
                       :information="selected_intervention.prestataire !== null ? selected_intervention.prestataire.fullname : 'Non défini'"></detail-card>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" sm="4" xl="4">
              <detail-card icon="mdi-calendar" name="Relancé le"
                           :information="selected_intervention.last_reminder != null ? formatDate(selected_intervention.last_reminder) : 'Non planifié'"/>
            </v-col>
            <v-col cols="12" sm="4" xl="4">
              <detail-card name="Demandé le" icon="mdi-calendar"
                           :information="formatDate(ticket.date_creation)"></detail-card>
            </v-col>
            <v-col cols="12" sm="4" xl="4">
              <detail-card icon="mdi-calendar" name="Réalisée le"
                           :information="selected_intervention.date_realisation != null ? formatDate(selected_intervention.date_realisation) : 'Non planifié'"/>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <v-row no-gutters v-if="isResponsableOrAdmin">
        <v-col>
          <v-dialog v-model="reminderDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary">
            <v-icon>mdi-bell</v-icon>
              Envoyer un rappel
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 primary white--text">
              Envoyer un rappel à ce prestataire
            </v-card-title>

            <v-card-text class="pa-2">
              <p>
                Voulez-vous envoyer un rappel à ce prestataire ?
              </p>
              <p class="text--secondary">
                ATTENTION : Cette fonctionnalité vous permet d'envoyer un email de rappel à votre prestataire.
                En abuser peut faire la boîte mail de votre destinataire vous détecter en tant que spam et il ne
                recevra plus de mises à jour de votre part.
              </p>
            </v-card-text >

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="sendReminder"
              >
                Valider
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-col>

      </v-row>
      <v-divider class="my-2"></v-divider>
      <v-row no-gutters v-if="logged_user != null && logged_user.group === 'Prestataire'">
        <v-btn v-if="selected_intervention.date_intervention === null" color="primary" @click="openPropositionDrawer()">
          Accepter et proposer une heure
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" v-if="!selected_intervention.validation" @click="refuseIntervention()">Refuser</v-btn>
        <v-spacer></v-spacer>
      </v-row>
      <v-row no-gutters v-if="logged_user !== null && isResponsableOrAdmin">
        <v-btn @click="generateBon" color="primary"><v-icon>mdi-document</v-icon>Générer le bon d'intervention</v-btn>
        <v-dialog v-model="cancelDialog" max-width="500">
          <template v-slot:activator="{on, attrs}">
            <v-btn color="red" v-on="on" v-bind="attrs" class="white--text mx-2">
              <v-icon>mdi-close</v-icon>
              Annuler l'intervention
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5 red white--text">
              Annuler l'intervention
            </v-card-title>

            <v-card-text class="pa-2">
              <p>
                Cette action annulera l'intervention et passera le ticket correspondant en "clôturé".
              </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="red"
                  text
                  @click="cancelIntervention"
              >
                Confirmer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-if="!selected_intervention.validation"
               color="primary" @click="acceptIntervention">Valider
          l'intervention
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="selected_intervention.prestataire === null" color="orange"
               @click="$emit('open-prestataire-drawer')" class="white--text">Assigner à un nouveau
          prestataire
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="selected_intervention.prestataire === null" color="orange"
               @click="$emit('open-assignment-drawer');" class="white--text">Assigner à un nouveau
          collaborateur
        </v-btn>
      </v-row>

      <v-row no-gutters class="py-4 ml-3" align="center"
             v-if="(isIntervenant || isAffected()) && selected_intervention.validation && selected_intervention.date_realisation === null">
        <v-col cols="12">
          <p class="font-weight-bold">Messages rapides</p>
        </v-col>
        <v-col class="pr-2 mt-1">
          <v-btn @click="confirmIntervention('L\'intervention a été réalisée.', false)" color="primary">Intervention
            réalisé
          </v-btn>
        </v-col>
        <v-col class="pr-2 mt-1">
          <v-btn v-if="!selected_intervention.waiting"
                 @click="confirmIntervention('Je suis en attente de pièces.', true)" color="primary">Attente de pièces
          </v-btn>
        </v-col>
        <v-col class="pr-2 mt-1">
          <v-btn v-if="!selected_intervention.waiting"
                 @click="confirmIntervention('L\intervention est en cours.', true)" color="primary">En cours
          </v-btn>
        </v-col>
        <v-col class="pr-2 mt-1">
          <v-btn v-if="!selected_intervention.waiting"
                 @click="confirmIntervention('L\intervention est en pause.', true)" color="primary">En pause
          </v-btn>
        </v-col>
        <v-col class="pr-2 mt-1">
          <v-btn v-if="!selected_intervention.waiting" @click="confirmIntervention('J\'ai envoyé le devis.', true)"
                 color="primary">Envoi du devis
          </v-btn>
        </v-col>
      </v-row>
      <Notation v-if="selected_intervention.date_realise !== null" :key="selected_intervention.id"/>
      <v-divider></v-divider>
      <v-tabs icons-and-text v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab>
          Commentaires
          <v-icon>mdi-message</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Commentaires :ticket="ticket" :key="ticket.id"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card v-else-if="!loaded_intervention">
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import DetailCard from "@/components/base/DetailCard";
import TicketMixin from "@/mixins/tickets/TicketMixin";
import InterventionStatutChip from "@/components/interventions/InterventionStatutChip";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import Commentaires from "@/components/tickets/Commentaires";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import Notation from "@/views/Notation";
import HourPropositionForm from "@/components/interventions/HourPropositionForm";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import InterventionEditionForm from "@/components/interventions/InterventionEditionForm";

export default {
  name: "InterventionView",
  components: {Notation, InterventionStatutChip, DetailCard, Commentaires},
  mixins: [TicketMixin, AuthMixin, DateUtilMixin, DrawerMixin],
  async mounted() {
    if (this.$route.params?.id !== undefined) {
      await this.$store.dispatch("interventions/getInterventionById", this.$route.params?.id);
    }
    this.loaded_intervention = true;
    let that = this;
    this.$root.$on('reloadInterventionPage', async () => {
      await that.loadIntervention();
      that.$forceUpdate();
    })
  },
  data() {
    let intervention = this.$store.getters["interventions/getSelectedIntervention"];
    return {
      tab: null,
      cout: intervention ? intervention.cout_achat : null,
      note: intervention ? intervention.note : null,
      financeDialog: false,
      reminderDialog: false,
      cancelDialog: false,
      loaded_intervention: false,
    }
  },
  computed: {
    selected_intervention() {
      return this.$store.getters["interventions/getSelectedIntervention"];
    },
    ticket() {
      return this.selected_intervention.ticket;
    },
    ticket_name() {
      return `Ticket #${this.ticket.id} : ${this.ticket.objet}`;
    },
    site_name() {
      let siteObject = this.ticket.asset.site;
      if (siteObject === null) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    },
    logged_user() {
      return this.$store.getters["users/getLoggedUser"];
    },
    canNoteIntervention() {
      let isNotSelfAssigned = parseInt(this.logged_user?.id) !== parseInt(this.selected_intervention.prestataire?.id);
      let hasRatingPermission = [
        "Responsable technique",
        "Administrateur",
        "Chef de rayon",
        "Chef de département"
      ].indexOf(this.logged_user?.group) !== -1;
      let isTicketCreator = this.ticket?.created_by.id === this.logged_user?.id;
      return isNotSelfAssigned && (hasRatingPermission || isTicketCreator);
    },
  },
  methods: {
    editIntervention() {
      this.openDrawerDialog(InterventionEditionForm, {key: this.selected_intervention_id});
    },
    openPropositionDrawer() {
      this.openDrawerDialog(HourPropositionForm, {});
    },
    isAffected() {
      return this.logged_user?.id === this.selected_intervention.prestataire?.id;
    },
    async refuseIntervention() {
      let ticketId = this.selected_intervention.ticket.id;
      let intervention = {
        "id": this.selected_intervention.id,
        "date_intervention": null,
        "date_fin_intervention": null,
        "prestataire": null,
      }
      await this.$store.dispatch("tickets/patchTicket", {"id": ticketId, "state": "unassigned"});
      await this.$store.dispatch("interventions/deleteIntervention", intervention);
      await this.$store.dispatch("messages/announceSuccess", "Merci de votre réponse, vous n'êtes plus affecté à cette intervention.")
      this.$emit("close-proposition-drawer");
    },
    async acceptIntervention() {
      let ticketId = this.selected_intervention.ticket.id;
      let intervention = {
        "id": this.selected_intervention.id,
        "validation": true,
      }
      await this.$store.dispatch("interventions/patchIntervention", intervention);
      await this.$store.dispatch("tickets/patchTicket", {"id": ticketId, "state": "processed"});
      this.closeDrawerDialog();
    },
    async generateBon() {
      await this.$store.dispatch("interventions/generateBon", this.selected_intervention.id);
    },
    async confirmIntervention(message, isWaiting) {
      let commentaire = {
        ticket: this.ticket.id,
        attachment: null,
        created_by: this.logged_user != null ? this.logged_user.id : null,
        message,
      }
      this.loading = true;
      await this.$store.dispatch("commentaires/postCommentaire", {ticket: this.ticket, commentaire});
      await this.$store.dispatch("interventions/patchIntervention", {
        id: this.selected_intervention.id,
        date_realisation: isWaiting ? null : this.getNow(),
        waiting: isWaiting
      })
      await this.$store.dispatch("tickets/patchTicket", {id: this.ticket.id, state: 'processed'});
      this.loading = false;
    },
    async cancelIntervention() {
      let ticketId = this.ticket.id;
      this.reminderDialog = false;
      await this.$store.dispatch("tickets/patchTicket", {id: this.ticket.id, state: 'closed'});
      await this.$store.dispatch("interventions/deleteIntervention", this.selected_intervention);
      await this.$router.push(`/tickets/${ticketId}`);
      this.$store.dispatch("messages/announceSuccess", "L'intervention a bien été annulée.")
    },
    async setCostAndRating() {
      try {
        await this.$store.dispatch("interventions/patchIntervention", {
          id: this.selected_intervention.id,
          cout_achat: this.cout,
          note: this.note,
        });
        await this.$store.dispatch("tickets/getTicketById", this.selected_intervention.ticket.id)
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Prix et note affecté."
        });
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue."
        });
      }
    },
    async setCost() {
      try {
        await this.$store.dispatch("interventions/patchIntervention", {
          id: this.selected_intervention.id,
          cout_achat: this.cout,
        });
        await this.$store.dispatch("tickets/getTicketById", this.selected_intervention.ticket.id)
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Prix affecté."
        });
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue."
        });
      }
    },
    async setRating() {
      try {
        await this.$store.dispatch("interventions/patchIntervention", {
          id: this.selected_intervention.id,
          note: this.note,
        });
        await this.$store.dispatch("tickets/getTicketById", this.selected_intervention.ticket.id)
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Intervention noté."
        });
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue."
        });
      }
    },
    async sendReminder() {
      await this.$store.dispatch("interventions/sendInterventionReminder", this.selected_intervention.id);
      this.reminderDialog = false;
    }
  }
}
</script>

